/* :root {
  --primaryClr: #000336;
  --whiteClr: #fff;
  --blackClr: #000;
  --greenClr: #10aa69;
  --blueClr: #559fd6;
  --lightBdr: #dedede;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

default score card
.scorecardBackground {
  background-color: var(--primaryClr);
  min-height: 100vh;
  padding: 40px 10px;
}

.scorecard-box {
  width: 75%;
  margin: 0 auto;
}

Soccer & Tennis score card

.scorecard-box .scorecard-inprogress p {
  background: #e01c1c;
  color: var(--whiteClr);
  width: 12%;
  text-align: center;
  padding: 3px;
  margin: 0 auto 12px;
}
.scorecard-box .scorecard-middlecontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.scorecard-box .scorecard-middlecontent .scorecard-matchleft {
  display: flex;
  width: 41%;
}
.scorecard-box
  .scorecard-middlecontent
  .scorecard-matchleft
  .scorecard-table
  table {
  border: 0;
  
}
.scorecard-box
  .scorecard-middlecontent
  .scorecard-matchleft
  .scorecard-table
  table
  tbody::-webkit-scrollbar {
  width: 2px;
}

.scorecard-box
  .scorecard-middlecontent
  .scorecard-matchleft
  .scorecard-table
  table
  tbody::-webkit-scrollbar-thumb {
  border-radius: 5px;
  width: 8px;
  background: rgb(70 73 114);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.scorecard-box
  .scorecard-middlecontent
  .scorecard-matchleft
  .scorecard-table
  table
  tbody
  tr {
  display: table;
  width: 100%;
}
.scorecard-box
  .scorecard-middlecontent
  .scorecard-matchleft
  .scorecard-table
  table
  thead {
  border: 0;
  background-color: var(--whiteClr);
  border: 0;
}
.scorecard-box
  .scorecard-middlecontent
  .scorecard-matchleft
  .scorecard-table
  table
  tr {
  border: 0;
}
.scorecard-box
  .scorecard-middlecontent
  .scorecard-matchleft
  .scorecard-table
  table
  tbody {
  display: table-caption;
  overflow-y: scroll;
  height: 340px;
}
.scorecard-box
  .scorecard-middlecontent
  .scorecard-matchleft
  .scorecard-table
  table
  thead
  tr
  th {
  font-size: 12px;
  text-align: center;
  border: 0;
  color: #000;
}
.scorecard-box
  .scorecard-middlecontent
  .scorecard-matchleft
  .scorecard-table
  table
  thead
  tr
  th:first-child {
  border-radius: 25px 0 0 25px;
}
.scorecard-box
  .scorecard-middlecontent
  .scorecard-matchleft
  .scorecard-table
  table
  thead
  tr
  th:last-child {
  border-radius: 0 25px 25px 0;
}
.scorecard-box
  .scorecard-middlecontent
  .scorecard-matchleft
  .scorecard-table
  table
  tbody
  tr
  td {
  border: 0;
  font-size: 12px;
  text-align: center;
  color: var(--whiteClr);
}
.scorecard-box
  .scorecard-middlecontent
  .scorecard-matchleft
  .scorecard-matchflag-name {
  display: flex;
}
.scorecard-matchleft .scorecard-matchflag-name {
  margin-left: 15px;
}

.scorecard-matchleft .scorecard-matchflag-name img {
  border: 2px solid var(--whiteClr);
  border-radius: 50%;
  padding: 6px;
  width: 115px;
  height: 115px;
  margin-right: 8px;
}

.scorecard-matchleft .country-name h3 {
  color: var(--whiteClr);
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.scorecard-matchleft .country-name {
  height: 115px;
  display: flex;
  align-items: center;
}
.scorecard-matchleft .country-name h3 span {
  font-size: 13px;
}
.scorecard-rightpart .scorecard-matchflag-name {
  margin-left: 0;
  margin-right: 15px;
}
.scorecard-box .scorecard-vspart p {
  margin: 0;
  font-size: 32px;
  font-weight: 700;
  color: var(--whiteClr);
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #e01c1c;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.scorecard-box .scorecard-vspart p::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 45px;
  background-color: var(--whiteClr);
  right: 27px;
  top: 7px;
  transform: rotate(10deg);
}
.scorecard-oddspart ul {
  background-color: #252525;
  padding: 5px 18px 20px;
  display: flex;
  justify-content: space-between;
  width: 70%;
  height: 55px;
  margin: 20px auto;
  border-radius: 3px;
}
.scorecard-oddspart ul li {
  list-style-type: none;
  width: 40px;
}
.scorecard-oddspart ul li {
  color: #000;
  display: flex;
  width: 100%;
  background-color: var(--whiteClr);
  height: 100%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  margin-right: 20px;
}
.scorecard-oddspart ul li.green {
  background-color: var(--greenClr);
  color: var(--whiteClr);
}

.scorecard-oddspart ul li.blue {
  background-color: var(--blueClr);
  color: var(--whiteClr);
}
.scorecard-box .statium-name {
  color: var(--whiteClr);
  padding: 8px 0;
  text-align: center;
  width: 22%;
  margin: 30px auto 0;
  font-size: 12px;
  border-top: 1px solid var(--whiteClr);
  border-bottom: 1px solid var(--whiteClr);
}
.scorecard-score-line p {
  margin: 10px 0;
  color: #fff;
}
.scorecard-mobile-view table {
  border: 0;
}
.scorecard-mobile-view table thead {
  border: 0;
  background-color: #000;
  border: 0;
}
.scorecard-mobile-view table tr {
  border: 0;
}
.scorecard-mobile-view table tbody tr {
  border: 0;
}
.scorecard-mobile-view table thead tr th {
  font-size: 12px;
  border: 0;
  color: var(--whiteClr);
}
.scorecard-rightpart .scorecard-matchflag-name {
  flex-direction: row-reverse;
  margin-right: 0;
}
.scorecard-mobile-view table thead tr th:first-child {
  border-radius: 5px 0 0 5px;
}
.scorecard-mobile-view table thead tr th:last-child {
  border-radius: 0 5px 5px 0;
}
.scorecard-mobile-view table tbody tr td {
  --bs-table-accent-bg: transparent !important;
  border: 0;
  font-size: 13px;
  font-weight: 600;
}
.scorecard-mobile-view ul {
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.scorecard-mobile-view ul.players-card {
  flex-direction: column;
  align-items: end;
}
.scorecard-mobile-view ul.players-card li span {
  height: 32px;
  width: 20px;
  margin: 0 4px;
  background-color: yellow;
  border: 0;
  border-radius: 0;
}
.scorecard-mobile-view ul.players-card li span.green {
  background-color: green;
}
.scorecard-mobile-view ul.players-card li span.red {
  background-color: red;
}
.scorecard-mobile-view ul.players-card.second-goal {
  flex-direction: row;
  border-bottom: 1px solid var(--lightBdr);
}
.scorecard-mobile-view ul.players-card.second-goal li {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.scorecard-mobile-view ul.players-card.second-goal span {
  height: auto;
  background: transparent;
  width: auto;
  margin: 0 10px;
  padding: 0;
}
.scorecard-mobile-view ul.players-card.second-goal span img {
  margin: 0;
}
.scorecard-mobile-view ul li {
  list-style-type: none;
  margin-bottom: 12px;
}
.scorecard-mobile-view ul li span {
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #333;
  padding: 4px 8px;
  border-radius: 5px;
  margin: 0 8px;
}
.scorecard-mobile-view ul li span img {
  width: 18px;
  margin-right: 8px;
}
.scorecard-table-mobile {
  background-color: var(--whiteClr);
  border-radius: 5px;
  padding: 20px;
}
.scorecard-table-mobile h3 {
  background-color: #000;
  border: 0;
  margin: 0;
  height: 40px;
  border-radius: 5px;
  color: #fff;
  padding-left: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.scorecard-match-time p {
  text-align: center;
  margin: 0;
}

match accordian
.cricketAccordian .accordion-item {
  background: transparent;
  border: 0;
  position: relative;
  margin-top: -34px;
}
.cricketAccordian.TennisAccordian .accordion-item {
  margin-top: 20px;
  margin-bottom: 20px;
}
.cricketAccordian .accordion-item .accordion-header button {
  animation: puls 3s linear infinite;
  position: relative;
  width: 50px;
  margin: 0 auto;
  height: 50px;
  background: #252525;
  padding: 0;
  border: 3px solid #000336;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cricketAccordian .accordion-item .accordion-header button::after {
  filter: invert(1);
  margin: 0;
}
.cricketAccordian .accordion-item .accordion-header button.collapsed {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

@keyframes puls {
  0% {
    box-shadow: 0 0 0 0 #000;
  }

  40% {
    box-shadow: 0 0 0 20px rgba(231, 75, 60, 0);
  }

  80% {
    box-shadow: 0 0 0 20px rgba(231, 75, 60, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(231, 75, 60, 0);
  }
}

.scorecardFootball .football-score-table h3 {
  background-color: var(--whiteClr);
  font-size: 22px;
  height: 35px;
  padding: 0 8px;
  margin: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: var(--blackClr);
  display: flex;
  justify-content: space-between;
}
.scorecardFootball .match-name {
  text-align: center;
  color: var(--whiteClr);
}
.scorecardFootball.scorecard-box {
  width: 80%;
}
.scorecardFootball .match-name h3 {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 45px;
}
.scorecardFootball .scorecard-inprogress p {
  font-size: 18px;
  width: 20%;
  font-weight: 600;
  margin-bottom: 42px;
}
.scorecardFootball .scorecard-matchflag-name {
  align-items: center;
}
.scorecardFootball .scorecard-matchflag-name h3 {
  margin-left: 22px;
}
.scorecardFootball .scorecard-rightpart .scorecard-matchflag-name h3 {
  margin-left: 0;
  margin-right: 22px;
}
.scorecardFootball .left-right-score p {
  color: var(--whiteClr);
  font-size: 20px;
  text-align: right;
  line-height: 15px;
  font-weight: 600;
  text-transform: uppercase;
}
.scorecardFootball .scorecard-middlecontent.scorecardBoxFoot {
  width: 90%;
  margin: 0 auto;
}
.scorecardFootball .scorecard-middlecontent.scorecardBoxFoot > div {
  flex: 1 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scorecardFootball p.footballscore {
  text-align: center;
  margin: 0;
  color: var(--whiteClr);
}
.scorecardFootball.scorecard-box .statium-name {
  margin: 12px auto 0;
}
.scorecardFootball .scorefootball p {
  color: var(--whiteClr);
  font-size: 20px;
  text-align: right;
  line-height: 15px;
  font-weight: 600;
  text-transform: uppercase;
}
.scorecardFootball .left-right-score h1 {
  text-align: center;
  color: var(--whiteClr);
  font-size: 82px;
  font-weight: 700;
}
.scorecardFootball .football-player-name {
  height: 200px;
  overflow-y: scroll;
}
.scorecardFootball .football-player-name::-webkit-scrollbar {
  width: 5px;
}
.scorecardFootball .football-player-name::-webkit-scrollbar-thumb {
  border-radius: 5px;
  width: 8px;
  background: rgb(70 73 114);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
scorecard tennis
.scorecardTennis .match-name {
  text-align: center;
  color: var(--whiteClr);
}
.scorecardTennis .match-name h3 {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 45px;
}
.scorecardTennis .scorecard-inprogress p {
  font-size: 18px;
  width: 20%;
  font-weight: 600;
  margin-bottom: 42px;
}
.scorecardTennis.scorecard-box {
  width: 80%;
}
.scorecardTennis .scorecard-matchflag-name {
  flex-direction: column;
  justify-content: center;
  margin: 0;
}
.scorecardTennis .scorecard-matchflag-name .player-name {
  border: 1px solid var(--whiteClr);
  color: var(--whiteClr);
  margin-top: 20px;
  text-align: center;
}
.scorecardTennis .scorecard-matchflag-name .player-name h3 {
  margin: 0;
  padding: 8px;
  font-size: 18px;
}
.scorecardTennis .scorecard-matchflag-name img {
  border: 2px solid var(--whiteClr);
  border-radius: 50%;
  padding: 6px;
  width: 180px;
  height: 180px;
}
.scorecardTennis .scorecard-vspart p {
  font-size: 100px;
  width: 320px;
  height: 132px;
  background-color: transparent;
  color: var(--whiteClr);
  font-weight: 700;
  margin: 0;
}
.scorecardTennis .scorecard-vspart p::before {
  display: none;
}

.scorecardTennis .scorecard-vspart p.tennis-score {
  
  width: auto;
  height: auto;
  font-size: 32px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #e01c1c;
}
.scorecardTennis .tennis-score-table {
  margin-top: 25px;
}

.scorecardTennis .tennis-score-table h3 {
  background-color: var(--whiteClr);
  font-size: 22px;
  height: 35px;
  padding: 0 8px;
  margin: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: var(--blackClr);
}
.scorecardTennis .tennis-score-table table {
  margin-top: 10px;
  border: 0;
}
.scorecardTennis .tennis-score-table table tbody tr {
  border: 0;
}
.scorecardTennis .tennis-score-table table tbody tr:last-child td {
  border-bottom: 1px solid #fff;
}

.scorecardTennis .tennis-score-table table tbody tr td {
  font-size: 18px;
  color: var(--whiteClr);
  border: 0;
}
.scorecardTennis .scorecard-match-time p {
  display: flex;
  justify-content: center;
  color: var(--whiteClr);
  font-weight: 600;
}
.scorecardTennis .scorecard-match-time p span {
  margin-left: 25px;
}

.scorecard-score-line {
  text-align: center;
}

.scorecard-box
  .scorecard-middlecontent
  .scorecard-matchleft.scorecard-rightpart {
  justify-content: end;
}

.scorecard-box
  .scorecard-middlecontent
  .scorecard-matchleft.scorecard-rightpart
  .scorecard-matchflag-name
  img {
  margin-right: 0px;
  margin-left: 8px;
}

.scorecard-oddspart ul li {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.scorecard-mobile-view table thead tr th:first-child,
.scorecard-mobile-view table tbody tr th:first-child {
  width: 26%;
  max-width: 26%;
}

.scorecard-mobile-view table thead tr th,
.scorecard-mobile-view table tbody tr td {
  width: 10%;
}

@media only screen and (max-width: 767px) {
  .scorecardBackground {
    padding: 20px 10px;
  }

  .scorecard-matchleft .scorecard-matchflag-name img {
    height: 45px !important;
    width: 45px !important;
  }

  .scorecardFootball .match-name h3,
  .scorecardTennis .match-name h3 {
    font-size: 18px !important;
    margin-bottom: 18px !important;
  }

  .scorecard-table-mobile {
    padding: 10px !important;
  }

  .scorecard-box {
    width: 100% !important;
  }

  .scorecard-oddspart ul {
    padding: 5px !important;
    width: 100% !important;
  }

  .scorecard-box .scorecard-inprogress p {
    font-size: 14px;
    margin-bottom: 15px;
    width: 45% !important;
  }

  .scorecard-box .scorecard-middlecontent {
    align-items: center;
    justify-content: center;
  }

  .scorecard-matchleft .scorecard-matchflag-name {
    margin-left: 0;
  }

  .scorecard-oddspart ul li {
    font-size: 10px;
    height: 30px;
    flex: 1 !important;
    width: auto;
    margin-right: 4px;
  }

  .cricketAccordian .accordion-body {
    padding: 5px;
  }

  .cricketAccordian.TennisAccordian .accordion-item {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .scorecard-box .statium-name {
    width: 80%;
  }
  .scorecard-box .scorecard-middlecontent .scorecard-matchleft,
  .scorecard-matchflag-name {
    width: 100%;
  }
  .scorecard-box .country-name-mv {
    justify-content: space-between;
    width: 100%;
    align-items: center;
    color: var(--whiteClr);
  }
  .scorecard-matchleft .country-name h3 {
    margin-left: 0;
    text-align: right;
    font-size: 11px;
  }

  .scorecard-matchleft .country-name h3 span {
    font-size: 11px;
  }

  .scorecardTennis .scorecard-vspart p.tennis-score {
    margin-top: -20px !important;
    padding-bottom: 20px;
    font-size: 16px;
  }

  .scorecard-box .scorecard-vspart p {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }

  .scorecard-box .scorecard-vspart p:before {
    height: 22px;
    right: 17px;
    top: 7px;
  }

  .scorecard-mobile-view {
    overflow-x: auto;
  }

  .scorecard-mobile-view table tbody tr td {
    font-size: 11px;
  }

  
  .scorecard-mobile-view ul li {
    font-size: 12px !important;
    flex-wrap: wrap;
  }

  .scorecard-mobile-view ul li span {
    height: 28px;
    margin: 0;
  }

  .scorecardFootball .scorecard-middlecontent.scorecardBoxFoot {
    flex-direction: row;
  }

  .scorecardFootball .left-right-score p {
    font-size: 9px !important;
    line-height: 4px !important;
  }

  .scorecardFootball .match-name h3,
  .scorecardTennis .match-name h3 {
    font-size: 24px !important;
    margin-bottom: 24px !important;
  }
  .scorecard-box
    .scorecard-middlecontent
    .scorecard-matchleft
    .scorecard-matchflag-name {
    align-items: center;
  }
  .scorecardTennis .scorecard-matchflag-name img {
    height: 58px !important;
    width: 58px !important;
    padding: 2px !important;
    margin-right: 8px;
  }
  .tennis-score-table .scorecard-table {
    display: block;
  }
  .scorecardTennis .tennis-score-table table tbody tr td {
    font-size: 14px !important;
  }
  .scorecardTennis .scorecard-matchflag-name.scorecard-vspart {
    margin: 15px;
    border: 0;
  }
  .scorecardTennis .scorecard-vspart p {
    font-size: 50px;
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
  }
}
*/

.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid lightblue;
  border-right-color: orange;
  animation: l2 1s infinite linear;
  position: absolute;
  top: 36%;
  left: 46%;
  transition: all 1s ease-out;
}
@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}

.scorecard-pre-loader {
  height: 13rem;
  align-items: center;
  position: relative;
  animation: fadeBackground 6s;
  animation-fill-mode: forwards;
  background-color: #093a7a;
  color: #fff;
}

.scorecard-main-message {
  padding: 20px 15px;
  height: 13rem;
  align-items: center;
  position: relative;
  background-color: #093a7a;
  color: #fff;
}

.scorecard-main-message .scorecard-box {
  width: 100%;
  margin: auto;
}

.scorecard-main-message .scorecard-box .scorecard-inprogress p {
  background: transparent;
  color: var(--whiteClr);
  width: 100%;
  text-align: center;
  padding: 3px;
  margin: auto;
}

/* New Scorecard Design */
.scorecard-main-container.scorecardCricket {
  padding: 20px 15px;
  background: linear-gradient(0deg, rgb(0 0 0 / 39%), rgb(0 0 0 / 30%)),
    url(https://www.stageandscreen.travel/sites/default/files/styles/large/public/LP%20-%20Cricket%20Australia.jpg?itok=dStxvjPW);
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: auto;
  margin-left: auto;
  color: white;
  height: 13rem;
  align-items: center;
  display: grid;
  background-position: bottom;
  position: relative;
}

.scorecard-main-container.scorecardTennis {
  background: linear-gradient(0deg, rgb(0 0 0 / 39%), rgb(0 0 0 / 30%)),
    url("../images/tennis.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: auto;
  margin-left: auto;
  color: white;
  height: 13rem;
  align-items: center;
  display: grid;
  background-position: bottom;
  position: relative;
}

.scorecard-main-container.scorecardSoccer {
  background: linear-gradient(0deg, rgb(0 0 0 / 39%), rgb(0 0 0 / 30%)),
    url("../images/soccer1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: auto;
  margin-left: auto;
  color: white;
  height: 13rem;
  align-items: center;
  display: grid;
  background-position: bottom;
  position: relative;
}

.scorecard-main-container.scorecardSoccer:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgb(7 7 7 / 24%);
}

.scorecard-main-container.hide {
  display: none;
}

.scorecard-main-container .scorecard-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 0;
}

.scorecard-main-container .scorecard-row .team-content {
  flex: 0 0 25%;
  max-width: 25%;
  text-align: center;
  z-index: 1;
}

.scorecard-main-container.scorecardTennis .scorecard-row .team-content {
  flex: 0 0 35%;
  max-width: 35%;
  text-align: center;
}

.scorecard-main-container.scorecardTennis .scorecard-row .team-content .score {
  font-size: 40px;
  margin-bottom: 0;
}

.scorecard-main-container .scorecard-row .team-content .team_name {
  text-transform: capitalize;
  font-size: 20px;
  margin: 0;
  font-weight: 800;
}

.scorecard-main-container.scorecardCricket
  .scorecard-row
  .team-content
  .team_name {
  text-transform: uppercase;
  font-size: 24px;
  margin: 0;
  font-weight: 900;
}

.scorecard-main-container .scorecard-row .team-content .curr_inn {
  font-size: 16px;
  font-weight: 700;
}

.scorecard-main-container.scorecardCricket
  .scorecard-row
  .team-content
  .curr_inn
  .run {
  font-size: 18px;
  font-weight: 900;
}

.scorecard-main-container.scorecardCricket
  .scorecard-row
  .team-content
  .curr_inn
  .over {
  font-size: 18px;
  font-weight: 900;
}

.scorecard-main-container .scorecard-row .match_status {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 0px;
  padding: 4px 0;
  z-index: 1;
}

.scorecard-main-container.scorecardTennis .scorecard-row .match_status {
  flex: 0 0 30%;
  max-width: 30%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 0px;
  padding: 4px 0;
}

.scorecard-main-container .scorecard-row .match_status .commantry {
  -webkit-animation: txt 3s ease-out infinite;
  animation: txt 3s ease-out infinite;
  font-family: tahomabd;
  font-size: 18px;
  width: 50%;
  display: block;
  position: absolute;
  top: 5%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.scorecard-main-container.scorecardTennis
  .scorecard-row
  .match_status
  .commantry {
  width: 30%;
}

.scorecard-main-container .scorecard-row .match_status .target {
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 5px;
}

.scorecard-main-container .scorecard-row .match_status .day {
  width: 100%;
  display: block;
  text-transform: capitalize;
  font-size: 25px;
}
.scorecard-main-container .scorecard-row .match_status .score-over ul {
  padding-left: 0;
}

.scorecard-main-container .scorecard-row .match_status .score-over ul li {
  display: inline-block;
  color: #fff;
}

.scorecard-main-container
  .scorecard-row
  .match_status
  .score-over
  ul
  li.six-balls {
  padding: 5px;
  font-size: 18px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
a,
div,
body {
  font-family: Helvetica, sans-serif !important;
}

.statium-name {
  color: var(--whiteClr);
  padding: 8px 0;
  text-align: center;
  width: 50%;
  margin: 30px auto 0;
  font-size: 12px;
  border-top: 1px solid var(--whiteClr);
  border-bottom: 1px solid var(--whiteClr);
}

.scorecard-main-container b {
  margin: 0 auto;
  font-size: 20px;
  font-weight: 700;
  color: var(--whiteClr);
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #e01c1c;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.scorecard-main-container b::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 30px;
  background-color: var(--whiteClr);
  right: 22px;
  top: 6px;
  transform: rotate(10deg);
}

.scorecard-main-container.scorecardSoccer
  .scorecard-row
  .match_status
  .commantry.Live {
  color: #2fd62f !important;
}

.scorecard-main-container.scorecardSoccer
  .scorecard-row
  .match_status
  .day
  .set-servie_score
  .event-half-time {
  color: #eb4646;
  font-weight: 900;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .scorecard-main-container.scorecardCricket {
    height: 13rem;
  }

  .scorecard-main-container.scorecardSoccer {
    height: 13rem;
  }

  .scorecard-main-container.scorecardTennis {
    height: 13rem;
  }

  .scorecard-main-container.scorecardCricket .scorecard-row .team-content {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .scorecard-main-container.scorecardCricket .scorecard-row .match_status {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .scorecard-main-container.scorecardCricket
    .scorecard-row
    .match_status
    .commantry {
    width: 40%;
  }
  .scorecard-main-container.scorecardCricket
    .scorecard-row
    .team-content
    .team_name {
    font-size: 16px;
    font-weight: 700;
  }
  /* .scorecard-main-container .scorecard-row .team-content .team_name {
    font-size: 16px;
    font-weight: 700;
  } */

  .scorecard-main-container.scorecardTennis
    .scorecard-row
    .team-content
    .team_name {
    font-size: 20px;
  }

  .scorecard-main-container .scorecard-row .team-content .curr_inn {
    font-size: 12px;
    font-weight: 600;
  }

  .scorecard-main-container .scorecard-row .match_status .commantry {
    font-size: 10px;
  }

  .scorecard-main-container.scorecardCricket
    .scorecard-row
    .team-content
    .curr_inn
    .run {
    font-size: 12px;
    font-weight: 700;
  }

  .scorecard-main-container.scorecardCricket
    .scorecard-row
    .team-content
    .curr_inn
    .over {
    font-size: 10px;
    font-weight: 700;
  }

  .scorecard-main-container.scorecardSoccer
    .scorecard-row
    .match_status
    .commantry {
    font-size: 14px;
  }

  .scorecard-main-container.scorecardSoccer
    .scorecard-row
    .match_status
    .commantry.Live
    .svg-inline--fa {
    height: 0.8em;
  }

  .scorecard-main-container.scorecardTennis
    .scorecard-row
    .match_status
    .commantry {
    font-size: 14px;
  }

  .scorecard-main-container
    .scorecard-row
    .match_status
    .score-over
    ul
    li.six-balls {
    padding: 2px;
    font-size: 14px;
  }

  .scorecard-main-container.scorecardTennis .scorecard-row .match_status .day {
    font-size: 18px;
  }

  .scorecard-main-container.scorecardSoccer .scorecard-row .match_status .day {
    font-size: 15px;
  }

  .scorecard-main-container .scorecard-row .match_status .target {
    font-size: 10px;
  }
  .scorecard-pre-loader {
    height: 13rem;
  }

  .loader {
    top: 35%;
    left: 45%;
  }
}

@media only screen and (max-width: 414px) {
  .scorecard-main-container .scorecard-row .team-content .team_name {
    font-size: 13px;
  }

  .scorecard-main-container .scorecard-row .team-content .curr_inn {
    font-size: 10px;
  }

  .scorecard-main-container .scorecard-row .team-content .curr_inn .run-rate {
    font-size: 9px;
  }

  .scorecard-main-container .scorecard-row .match_status .commantry {
    font-size: 10px;
  }

  .scorecard-main-container
    .scorecard-row
    .match_status
    .score-over
    ul
    li.six-balls {
    padding: 2px;
    font-size: 11px;
  }

  .scorecard-main-container.scorecardTennis
    .scorecard-row
    .team-content
    .score {
    font-size: 20px;
  }

  .scorecard-main-container.scorecardTennis
    .scorecard-row
    .team-content
    .team_name {
    font-size: 14px;
  }

  .scorecard-main-container .scorecard-row .match_status .day {
    font-size: 13px;
  }

  .scorecard-main-container .scorecard-row .match_status .target {
    font-size: 9px;
  }
}
